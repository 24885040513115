import {
  FLIC_PATIENT_STATUS_LIST,
  FLIC_DISCHARGE_STATUS_LIST,
  FLIC_MIN_REVIEW_TIPS
} from '@/utils/constant'
import {
  getBedList,
  getUserList
} from '@/services'
export default {
  patientInfoItemsTop: [
    {
      rowclass: '',
      gutter: 0,
      infoItems: [
        {
          title: '',
          prop: 'ic_str',
          label: 'IC',
          span: 12,
          infoclass: ''
        },
        {
          title: '',
          prop: 'gender',
          label: 'Gender',
          span: 12,
          infoclass: ''
        }
      ]
    },
    {
      rowclass: 'mt6',
      gutter: 0,
      infoItems: [
        {
          title: '',
          prop: 'birthday',
          label: 'DOB',
          span: 12,
          infoclass: ''
        },
        {
          title: '',
          prop: 'age',
          label: 'Age',
          span: 12,
          infoclass: ''
        }
      ]
    },
    {
      rowclass: 'mt6',
      gutter: 0,
      infoItems: [
        {
          title: '',
          prop: 'mdro',
          label: 'MDRO',
          span: 12,
          infoclass: 'textdanger'
        },
        {
          title: '',
          prop: 'mdro_remarks',
          label: 'MDRO Remarks',
          span: 12,
          infoclass: ''
        }
      ]
    },
    {
      rowclass: 'mt20',
      gutter: 0,
      infoItems: [
        {
          title: 'MSP Information',
          prop: '',
          label: '',
          span: 12,
          infoclass: ''
        },
        {
          title: 'MSW Information',
          prop: '',
          label: '',
          span: 12,
          infoclass: ''
        }
      ]
    },
    {
      rowclass: 'mt8',
      gutter: 0,
      infoItems: [
        {
          title: '',
          prop: 'msp_name',
          label: 'MSP Name',
          span: 12,
          infoclass: ''
        },
        {
          title: '',
          prop: 'msw_name',
          label: 'MSW Name',
          span: 12,
          infoclass: ''
        }
      ]
    },
    {
      rowclass: 'mt6',
      gutter: 0,
      infoItems: [
        {
          title: '',
          prop: 'msp_relation',
          label: 'Relationship',
          span: 12,
          infoclass: ''
        },
        {
          title: '',
          prop: 'msw_phone',
          label: 'MSW Phone',
          span: 12,
          infoclass: ''
        }
      ]
    },
    {
      rowclass: 'mt6',
      gutter: 0,
      infoItems: [
        {
          title: '',
          prop: 'msp_phone',
          label: 'MSP Phone',
          span: 12,
          infoclass: ''
        },
        {
          title: '',
          prop: 'msw_email',
          label: 'MSW Email',
          span: 12,
          infoclass: ''
        }
      ]
    },
    {
      rowclass: 'mt6',
      gutter: 0,
      infoItems: [
        {
          title: '',
          prop: 'msp_email',
          label: 'MSP Email',
          span: 24,
          infoclass: ''
        }
      ]
    },
    {
      rowclass: 'mt20',
      gutter: 0,
      infoItems: [
        {
          title: 'Remarks',
          prop: '',
          label: '',
          span: 24,
          infoclass: ''
        }
      ]
    },
    {
      rowclass: 'mt8',
      gutter: 0,
      infoItems: [
        {
          title: '',
          prop: 'remarks',
          label: '',
          span: 24,
          infoclass: ''
        }
      ]
    },
    {
      rowclass: 'mt20',
      gutter: 0,
      infoItems: [
        {
          title: 'Admission Details',
          prop: '',
          label: '',
          span: 24,
          infoclass: ''
        }
      ]
    },
    {
      rowclass: 'mt10',
      gutter: 0,
      infoItems: [
        {
          title: '',
          prop: 'planned_admission_date',
          label: 'Planned Admission Date',
          span: 12,
          infoclass: '',
          labelWidth: '150px'
        },
        {
          title: '',
          prop: 'bed',
          label: 'Assigned Bed',
          span: 12,
          infoclass: '',
          labelWidth: '150px'
        }
      ]
    },
    {
      rowclass: 'mt6',
      gutter: 0,
      infoItems: [
        {
          title: '',
          prop: 'actual_admission_date',
          label: 'Actual Admission Date',
          span: 12,
          infoclass: '',
          labelWidth: '150px'
        },
        {
          title: '',
          prop: 'to_institution_los',
          label: 'LOS in TCF as of Today',
          span: 12,
          infoclass: '',
          labelWidth: '150px',
          unit: 'Days'
        }
      ]
    },
    {
      rowclass: 'mt6',
      gutter: 0,
      infoItems: [
        {
          title: '',
          prop: 'raf',
          label: 'RAF',
          span: 12,
          infoclass: '',
          labelWidth: '150px'
        },
        {
          title: '',
          prop: 'min_review',
          label: 'Min Review Frequency',
          span: 12,
          infoclass: '',
          labelWidth: '150px'
        }
      ]
    },
    {
      rowclass: 'mt20',
      gutter: 0,
      infoItems: [
        {
          title: 'Discharge Details',
          prop: '',
          label: '',
          span: 24,
          infoclass: ''
        }
      ]
    },
    {
      rowclass: 'mt10',
      gutter: 0,
      infoItems: [
        {
          title: '',
          prop: 'planned_discharge_date',
          label: 'Planned Discharge Date',
          span: 12,
          infoclass: '',
          labelWidth: '150px'
        },
        {
          title: '',
          prop: 'planned_destination',
          label: 'Planned Discharge Destination',
          span: 12,
          infoclass: '',
          labelWidth: '150px'
        }
      ]
    },
    {
      rowclass: 'mt6',
      gutter: 0,
      infoItems: [
        {
          title: '',
          prop: 'actual_discharge_date',
          label: 'Actual Discharge Date',
          span: 12,
          infoclass: '',
          labelWidth: '150px'
        },
        {
          title: '',
          prop: 'actual_destination',
          label: 'Actual Discharge Destination',
          span: 12,
          infoclass: '',
          labelWidth: '150px'
        }
      ]
    },
    {
      rowclass: 'mt6',
      gutter: 0,
      infoItems: [
        {
          title: '',
          prop: 'discharge_status',
          label: 'Discharge Status',
          span: 24,
          infoclass: ''
        }
      ]
    },
    {
      rowclass: 'mt20',
      gutter: 0,
      infoItems: [
        {
          title: 'Referral Information',
          prop: '',
          label: '',
          span: 24,
          infoclass: ''
        }
      ]
    },
    {
      rowclass: 'mt10',
      gutter: 0,
      infoItems: [
        {
          title: '',
          prop: 'from_institution',
          label: 'Referring Institution',
          span: 24,
          infoclass: ''
        }
      ]
    },
    {
      rowclass: 'mt6',
      gutter: 0,
      infoItems: [
        {
          title: '',
          prop: 'referral_details',
          label: 'Referral Details',
          span: 24,
          infoclass: ''
        }
      ]
    }
  ],
  tableActions: [
    {
      label: 'Update Status',
      type: 'modal',
      action: 'POST',
      id: 'update_status',
      url: '/patients',
      dataObjKey: '',
      dialogText: 'status',
      dialogType: 'updateStatus',
      formItems: [
        {
          span: 15,
          formtype: 'select',
          prop: 'status',
          label: 'New Status',
          placeholder: 'Please select the New Status',
          options: FLIC_PATIENT_STATUS_LIST.slice(2),
          object: {
            style: 'width: 250px;',
            nextchange: true,
            rules: [
              { required: true, message: 'Please select the New Status', trigger: 'blur' }
            ]
          }
        },
        {
          span: 15,
          formtype: 'input',
          type: 'textarea',
          prop: 'remarks',
          label: 'Remarks',
          placeholder: 'Please enter the Remarks',
          object: {
            style: 'max-width: 450px;',
            rules: [
              { required: true, message: 'Please select the Remarks', trigger: 'blur' }
            ]
          }
        }
      ]
    },
    {
      label: 'Update Bed Number',
      type: 'modal',
      action: 'POST',
      id: 'update_bed',
      url: '/patients',
      dataObjKey: 'patient',
      dialogText: 'updating bed number',
      dialogType: 'updateBedNumber',
      datakey: 'bed',
      getDataFuncs: [
        {
          apifunc: () => getBedList({}, false),
          type: 'beds',
          fidx: 0,
          idx: 0
        }
      ],
      formItems: [
        {
          span: 15,
          formtype: 'select',
          prop: 'bed_id',
          label: 'Bed',
          placeholder: 'Please select the Bed',
          options: [],
          object: {
            style: 'width: 250px;',
            key: 'id',
            rules: [
              { required: true, message: 'Please select the Bed', trigger: 'blur' }
            ]
          }
        }
      ]
    },
    {
      label: 'Update Admission Date',
      type: 'modal',
      action: 'PUT',
      id: '',
      url: '/patients',
      dataObjKey: 'patient',
      dialogText: 'updating admission date',
      dialogType: 'updateAdmissionDate',
      datakey: 'planned_admission_date',
      formItems: [
        {
          span: 15,
          formtype: 'date',
          prop: 'planned_admission_date',
          label: 'Planned Admission Date',
          placeholder: 'Please select the Planned Admission Date',
          object: {
            style: 'width: 250px;',
            rules: [
              { required: true, message: 'Please select the  Planned Admission Date', trigger: 'blur' }
            ]
          }
        }
      ]
    },
    {
      label: 'Patient Review',
      type: 'modal',
      action: 'POST',
      id: 'create',
      url: '/notes',
      dataObjKey: 'note',
      dialogType: 'addNotes',
      dialogText: 'adding notes'
    },
    {
      label: 'Update Patient Remarks',
      type: 'modal',
      action: 'POST',
      id: 'update_remarks',
      url: '/patients',
      dataObjKey: 'patient',
      dialogText: 'updating patient remarks',
      dialogType: 'updatePatientRemarks',
      datakey: 'remarks',
      formItems: [
        {
          span: 15,
          formtype: 'input',
          type: 'textarea',
          prop: 'remarks',
          label: 'Remarks',
          placeholder: 'Please enter the Remarks',
          object: {
            style: 'max-width: 450px;',
            rules: [
              { required: true, message: 'Please enter the Remarks', trigger: 'blur' }
            ]
          }
        }
      ]
    },
    {
      label: 'Update Discharge Status',
      type: 'modal',
      action: 'PUT',
      id: '',
      url: '/patients',
      dataObjKey: 'patient',
      dialogText: 'updating discharge status',
      dialogType: 'updateDischargeStatus',
      datakey: 'discharge_status',
      formItems: [
        {
          span: 15,
          formtype: 'select',
          prop: 'discharge_status',
          label: 'Discharge Status',
          placeholder: 'Please select the Discharge Status',
          options: FLIC_DISCHARGE_STATUS_LIST,
          object: {
            style: 'width: 250px;',
            rules: [
              { required: true, message: 'Please select the Discharge Status', trigger: 'blur' }
            ]
          }
        }
      ]
    },
    {
      label: 'Update Clerking Doctor',
      type: 'modal',
      action: 'PUT',
      id: '',
      url: '/patients',
      dataObjKey: 'patient',
      dialogText: 'updating clerking doctor',
      dialogType: 'updateClerkingDoctor',
      datakey: 'doctor',
      getDataFuncs: [
        {
          apifunc: () => getUserList({role_eq: 'doctor'}, false),
          type: 'users',
          fidx: 0,
          idx: 0
        }
      ],
      formItems: [
        {
          span: 15,
          formtype: 'select',
          prop: 'doctor_id',
          label: 'Clerking Doctor',
          placeholder: 'Please select the Clerking Doctor',
          options: [],
          object: {
            style: 'width: 250px;',
            key: 'id',
            rules: [
              { required: true, message: 'Please select the Clerking Doctor', trigger: 'blur' }
            ]
          }
        }
      ]
    },
    {
      label: 'Update Min. Review Frequency',
      type: 'modal',
      action: 'PUT',
      id: '',
      url: '/patients',
      dataObjKey: 'patient',
      dialogText: 'updating minimum review frequency',
      dialogType: 'updateMinimumReviewFrequency',
      datakey: 'min_review',
      formItems: [
        {
          span: 20,
          formtype: 'inputnumber',
          prop: 'min_review',
          label: 'Minimum Review Frequency (Days)',
          placeholder: '',
          object: {
            style: 'width: 250px;',
            rules: [
              { required: true, message: 'Please enter the Min Review Frequency', trigger: 'blur' }
            ],
            tips: FLIC_MIN_REVIEW_TIPS
          }
        }
      ]
    },
    {
      label: 'Add TCU',
      type: 'modal',
      action: 'POST',
      id: 'create',
      url: '/tcus',
      dataObjKey: 'tcu',
      dialogType: 'addTcu',
      dialogText: 'adding new TCU',
      formItems: [
        {
          span: 15,
          formtype: 'datetime',
          prop: 'planned_at',
          label: 'Date/Time',
          placeholder: 'Please select ...',
          object: {
            style: 'width: 280px;',
            rules: [
              { required: true, message: 'Please select the Date/Time', trigger: 'blur' }
            ]
          }
        },
        {
          span: 15,
          formtype: 'input',
          type: 'textarea',
          prop: 'address',
          label: 'Address',
          placeholder: 'Please enter the Address',
          object: {
            style: 'max-width: 450px;',
            rules: [
              { required: true, message: 'Please select the Address', trigger: 'blur' }
            ]
          }
        },
        {
          span: 15,
          formtype: 'input',
          type: 'textarea',
          prop: 'remarks',
          label: 'Doctor Remarks',
          placeholder: 'Please enter the Doctor Remarks',
          object: {
            style: 'max-width: 450px;',
            rules: [
              { required: true, message: 'Please select the Doctor Remarks', trigger: 'blur' }
            ]
          }
        }
      ]
    }
  ],
  pageActions: [
    {
      label: 'List New Patient',
      type: 'jump',
      url: '/workplace/patientUpdate',
      action: '',
      id: 'create'
    }
  ],
  statusHistoryTableItems: [
    {
      label: 'Time',
      prop: 'created_at'
    },
    {
      label: 'Content',
      prop: 'from-to',
      isComputed: true
    },
    {
      label: 'Remarks',
      prop: 'remarks'
    }
  ],
  TCUTableItems: [
    {
      label: 'Time',
      prop: 'planned_at'
    },
    {
      label: 'Content',
      prop: 'remarks'
    }
  ],
  patientInfoItemsBottom: [
    {
      rowclass: 'mt20',
      gutter: 0,
      infoItems: [
        {
          title: 'Billing',
          prop: '',
          label: '',
          span: 24,
          infoclass: ''
        }
      ]
    },
    {
      rowclass: 'mt10',
      gutter: 0,
      infoItems: [
        {
          title: '',
          prop: 'diagnosis',
          label: 'Claim Diagnosis',
          span: 12,
          infoclass: ''
        },
        {
          title: '',
          prop: 'paid',
          label: 'Paid',
          span: 12,
          infoclass: ''
        }
      ]
    },
    {
      rowclass: 'mt6',
      gutter: 0,
      infoItems: [
        {
          title: '',
          prop: 'billed',
          label: 'Billed',
          span: 24,
          infoclass: ''
        }
      ]
    }
  ],
  viewEditAction: [
    {
      label: 'View/Edit All Infomation >>',
      type: 'jump',
      url: '/workplace/patientInfo/details',
      action: '',
      id: 'update'
    }
  ]
}