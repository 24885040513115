<template>
  <div class="quick-actions">
    <div class="textlightgray12 mb10">Quick Actions</div>
    <div class="quick-actions_btns d-flex flex-wrap">
      <el-button
        type="info"
        v-for="item in actions"
        :key="item.label"
        class="mb10 mr10"
        @click="$emit('action', item)"
      >
        {{ item.label }}
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    actions: {
      type: Array,
      default: () => []
    },
    id: {
      type: String,
      default: ''
    }
  },
  data () {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.quick-actions {
  // width: calc(100% - 30px);
  width: 100%;
  padding-top: 18px;
  position: sticky;
  right: 30px;
  bottom: 0;
  background: #fff;
  box-shadow: 0px -5px 10px -5px rgba($color: #DCE3EB, $alpha: 0.26);
  z-index: 100;
  &_btns {
    justify-content: start;
  }
}
::v-deep .el-button + .el-button {
  margin-left: 0;
}
</style>