<template>
  <div class="d-flex info-row">
    <div v-if="label" class="info-label" :style="`min-width: ${labelWidth}`">{{ label }}：</div>
    <div class="info-value" :class="infoclass">{{ infovalue }}</div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    infovalue: {
      type: [String, Number, Boolean],
      default: ''
    },
    infoclass: {
      type: String,
      default: ''
    },
    labelWidth: {
      type: String,
      default: '100px'
    }
  },
  data () {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.info-row {
  font-size: 12px;
  color: $text-color-darkblue;
  align-items: flex-start;
}

.info-label {
  color: $text-color-secondarygray;
  margin-right: 20px;
  line-height: 17px;
}
</style>