<template>
  <div class="patient-table mt15">
    <div class="workplace-list_total mb10 ">Total number in this list: <span>{{total}}</span></div>
    <div class="patient-table_main">
      <el-table
        :data="tableData"
        style="width: 100%"
        :highlight-current-row="true"
        :cell-style="{'vertical-align':'top'}"
        @row-click="(row, column) => $emit('rowclick', row, column)"
      >
        <el-table-column
          label="Patient"
          width="183">
          <template slot-scope="scope">
            <div class="patient-table_username textdarkblue-bold16">{{ scope.row.name }}</div>
            <div class="patient-table_ic mt5">{{ computedIc(scope.row.ic) }}</div>
            <div class="d-flex patient-table_mdro mt5">
              <span>MDRO:</span>
              <span class="patient-table_mdro_val" :class="{'textdanger': scope.row.mdro}">
                {{ scope.row.mdro ? 'Yes' : 'No' }}
              </span>
            </div>
            <div class="d-flex patient-table_clerk mt15">
              <span style="padding-right: 5px;">Clerk By:</span>
              <span>
                {{ cumputedProp(scope.row.doctor) }}
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="Information"
          width="183">
          <template slot-scope="scope">
            <div class="patient-table_txt d-flex">
              PHI: <span class="textdarkblue">{{ cumputedProp(scope.row.from_institution) }}</span>
            </div>
            <div class="patient-table_txt d-flex mt5">
              Assigned Bed: <span>{{ cumputedProp(scope.row.bed) }}</span>
            </div>
            <div class="patient-table_txt d-flex mt5">
              LOS in TCF: <span>{{ cumputedProp(scope.row.to_institution_los)}} Days</span>
            </div>
            <div class="patient-table_txt d-flex mt15">
              Dischange Planning: 
            </div>
            <div><span class="textdarkblue">{{ computedDischarge(scope.row)}}</span></div>
          </template>
        </el-table-column>
        <el-table-column
          label="Status"
          prop="status"
          width="100">
          <template slot-scope="scope">
            <div>{{ getComputedStatusName(scope.row.status) }}</div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div v-if="hasPagination" class="d-flex mt20">
      <el-pagination
        layout="total, sizes, prev, pager, next, jumper"
        :page-size="pageSize"
        @size-change="sizeChangeHandler"
        @current-change="currentChangeHandler"
        :page-sizes="[10, 20, 30, 40, 50]"
        :current-page.sync = "page"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {
  FLIC_PATIENT_STATUS_LIST
} from '@/utils/constant'
import utils from '@/utils/Utils'
export default {
  props: {
    hasPagination: {
      type: Boolean,
      default: true
    },
    getTableDataFunc: {
      type: Function,
      default () {
        return ''
      }
    },
    status: {
      type: String,
      default: ''
    },
    icKeywords: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      tableData: [],
      pageSize: 10,
      total: 0,
      page: 1
    }
  },
  created () {
    this.reload()
  },
  methods: {
    reload () {
      this.page = 1
      this.initData ()
    },
    changeStatus (id, patient) {
     const data = this.tableData.map((d) => {
        if (d.id == id) {
          return patient
        }
        return d
      })
      this.$set(this, 'tableData', data)
    },
    async initData() {
      try {
        const params = {
          status_eq: this.status,
          ic_cont: this.icKeywords,
          page: this.page,
          per_page: this.pageSize
        }
        const res = await this.getTableDataFunc(params)
        if (res.data.patients) {
          this.tableData = res.data.patients
          this.$emit('hasdata', this.tableData.length > 0 ? true : false)
          this.total = res.data.page.total_count
        }
      } catch (error) {
        
      }
    },
    sizeChangeHandler (val) {
      this.page = 1
      this.pageSize = val
      this.initData()
    },
    currentChangeHandler(val) {
      this.page = val
      this.initData()
    },
    cumputedProp (val) {
      if (utils.isPlainObject (val)) return val.name
      if (!isNaN(val)) return val
      return val || '-'
    },
    computedIc (ic) {
      return utils.encryptedStr(ic)
    },
    computedDischarge (data) {
      let str = ''
      if (data.planned_discharge_date) {
        str += data.planned_discharge_date
      }
      if (data.planned_destination) {
        if(str) str += ` (${data.planned_destination.name})`
        else str = data.planned_destination.name
      }
      return str || '-'
    },
    getComputedStatusName (status) {
      return utils.getComputedStatusName(FLIC_PATIENT_STATUS_LIST, status)
    }
  }
}
</script>

<style lang="scss" scoped>
.patient-table {
  width: calc(100% - 20px);
  height: 100%;
  &_main {
    max-height: calc(100% - 78px);
    overflow-y: auto;
  }
  &_ic {
    color: $text-color-primary;
  }
  &_mdro {
    color: $text-color-gray;
    font-size: 15px;
    &_val {
      padding-left: 5px;
    }
  }
  &_clerk {
    color: $text-color-gray;
  }
  &_txt {
    color: $text-color-secondarygray;
    span {
      color: $text-color-black;
      padding-left: 5px;
    }
    .textdarkblue {
      font-weight: 500;
    }
  }
}
::v-deep {
  .el-table__body tr.current-row > td.el-table__cell, 
  .el-table__body tr.selection-row > td.el-table__cell {
    background-color: $cancel-btn-color;
  }
  .el-table th.el-table__cell {
    background-color: #f2f3f5;
  }
}

</style>