<template>
  <div class="workplace-list">
    <div class="d-flex flex-end flex-wrap">
      <el-button
        v-for="(item, index) in pageActions"
        :key="item.id"
        :type="item.btnType || 'primary'"
        @click="$router.push(item.url)"
        :class="{'ml10': index > 0}"
      >
        {{ item.label }}
      </el-button>
    </div>
    <el-card class="mt15">
      <div class="workplace-list_main d-flex spacebetween">
        <div class="workplace-list_left">
          <div class="workplace-list_search d-flex">
            <div style="margin-right: 18px;">Status</div>
            <el-select v-model="status" placeholder="Please select the ...">
              <el-option
                v-for="item in statusList"
                :key="item.value"
                :label="item.name"
                :value="item.value">
              </el-option>
            </el-select>
            <el-input v-model="ic" placeholder="Search by IC" class="ml10" />
            <el-button type="info" class="ml10" @click="$refs.patientTable.reload()">Search</el-button>
          </div>
          <div class="workplace-left_table">
            <PatientTable
              ref="patientTable"
              :status="status"
              :icKeywords="ic"
              :getTableDataFunc="getPatientList"
              @rowclick="rowclickHandler"
              @hasdata="(flag) => hasData = flag"
            />
          </div>
        </div>
        <div class="workplace-list_right">
          <template v-if="patient">
            <div class="workplace-info d-flex spacebetween mb20">
              <div class="workplace-info_left">
                <div class="textdarkblue">{{patient.number || '-'}}</div>
                <div class="textdarkblue-bold16 workplace-info_name">{{patient.name}}</div>
                <div>
                  <span>Clerk By：</span>
                  <span class="textdarkblue">{{patient.doctor ? patient.doctor.name : '-'}}</span>
                </div>
              </div>
              <div class="workplace-info_right">
                <el-button
                  type="info"
                  v-for="item in workplaceEidtAction"
                  :key="item.id"
                  @click="$router.push(item.url + `?id=${patient.id}`)"
                >
                  {{ item.label }}
                </el-button>
                <div class="text-align-r textdarkblue-bold16 mt10">{{ patient.status_name }}</div>
              </div>
            </div>
            <div class="workplace-info-main">
              <div>
                <el-row
                  v-for="(item, index) in patientInfoItemsTop"
                  :key="index"
                  :class="item.rowclass"
                  :gutter="item.gutter"
                >
                  <el-col
                    v-for="(c, c_i) in item.infoItems"
                    :xs="24"
                    :sm="24" 
                    :md="24"
                    :lg="c.span"
                    :key="c_i + 'col'"
                  >
                    <div v-if="c.title" class="textlightgray12">{{ c.title }}</div>
                    <InfoGroupItem
                      :label="c.label"
                      :infovalue="cumputedProp(c)"
                      :infoclass="c.infoclass"
                      :label-width="c.labelWidth || '100px'"
                    />
                  </el-col>
                </el-row>
              </div>
              <NoHeaderTable
                subtitle="Upcoming 5 TCUs"
                :tableItems="TCUTableItems"
                :tableData="tcuList"
              />
              <div>
                <el-row
                  v-for="(item, index) in patientInfoItemsBottom"
                  :key="index"
                  :class="item.rowclass"
                  :gutter="item.gutter"
                >
                  <el-col  v-for="(c, c_i) in item.infoItems"
                    :xs="24"
                    :sm="24" 
                    :md="24"
                    :lg="c.span"
                    :key="c_i + 'col1'"
                  >
                    <div v-if="c.title" class="textlightgray12">{{ c.title }}</div>
                    <InfoGroupItem
                      :label="c.label"
                      :infovalue="cumputedProp(c)"
                      :infoclass="c.infoclass"
                      :label-width="c.labelWidth || '100px'"
                    />
                  </el-col>
                </el-row>
              </div>
              <NoHeaderTable
                key="status"
                subtitle="Status History" 
                :tableItems="statusHistoryTableItems"
                :tableData="statusLogList"
              />
            </div>
          </template>
          <div
            v-else
            class="flic-empty workplace-info-empty"
          >
            {{ hasData ? 'Select a patient on the left to start' : 'No Data'}}
          </div>
          <QuickActions v-if="hasData && patient" :actions="actions" @action="actionChangeHandler" />
        </div>
      </div>
    </el-card>
    <FormModal
      :dialogVisible.sync="dialogVisible"
      :formDataGroups="dialogFormDataGroups"
      :title="dialogTitle"
      @close="dialogVisible = false"
      @selectchange="(val, item, index, fidx) => selectchangeHandler(val, item, index, fidx, 'index')"
      @submit="dialogSubmitHandler"
      :dialogModifyData="dialogModifyData"
      :labelWidth="labelWidth"
    />
    <ConfirmModal
      v-if="patient"
      :dialogVisible.sync="confirmDialogVisible"
      :username="patient.name"
      :ic="patient.ic"
      :info="form"
      :comfirmHtml="comfirmHtml"
      :infoItems="confirmInfoItems"
      :isReviewNotes="isReviewNotes"
      :title="confirmDialogTitle"
      @close="confirmDialogVisible = false"
      @confirm="postSubmitHandler"
    />
  </div>
</template>

<script>
import FormModal from '@/components/FormModal'
import PatientTable from '../components/PatientTable'
import InfoGroupItem from '../components/InfoGroupItem'
import QuickActions from '../components/QuickActions'
import NoHeaderTable from '../components/InfoTable'
import {
  getPatientList
} from '@/services'
import {
  FLIC_PATIENT_STATUS_LIST
} from '@/utils/constant'
import { permitActionMixin } from '@/mixin/permitActionMixin'
import { patientInfoMixin } from '@/mixin/patientInfoMixin'
import utils from '@/utils/Utils'
import jsonData from './jsonData'
export default {
  components: {
    FormModal,
    PatientTable,
    InfoGroupItem,
    QuickActions,
    NoHeaderTable
  },
  mixins: [permitActionMixin, patientInfoMixin],
  data () {
    return {
      statusList: FLIC_PATIENT_STATUS_LIST,
      status: '',
      ic: '',
      getPatientList,
      patientInfoItemsTop: jsonData.patientInfoItemsTop,
      statusHistoryTableItems: jsonData.statusHistoryTableItems,
      TCUTableItems: jsonData.TCUTableItems,
      patientInfoItemsBottom: jsonData.patientInfoItemsBottom,
      hasNewPatientPermission: false,
      dialogVisible: false,
      dialogFormDataGroups: [],
      dialogTitle: '',
      selectPatientId: '',
      hasData: false,
      workplaceEidtAction: []
    }
  },
  created () {
    this.initPermit()
  },
  methods: {
    async initPermit () {
      await this.initActionData(jsonData, 'patients')
      this.workplaceEidtAction = jsonData.viewEditAction.filter(
        (d) => utils.hasPermission(this.permitActions, 'patients', d.id))
    },
    rowclickHandler (row, column) {
      if (row.id) {
        this.initData(row.id, row.ic)
      }
    },
    initData (id, ic, type) {
      this.initPatientInfo(id, type)
      this.initStatusLogList(id)
      this.initTcuList(id, ic)
    },
    cumputedProp (info) {
      const { patient } = this
      return utils.getComputedValFromProp(info, patient)
    }
  }
}
</script>

<style lang="scss" scoped>
.workplace-list {
  position: relative;
  color: $text-color-primary;
  height: 100%;
  &_left {
    width: $workplace-leftw;
    height: 100%;
    overflow-y: hidden;
  }
  &_main {
    align-items: flex-start;
    height: 100%;
  }
  &_search {
    position: sticky;
    width: 100%;
    left: 0;
    top: 0;
    background: #fff;
  }
  &_right {
    width: calc(100% - 40px - $workplace-leftw);
    position: relative;
    overflow-y: auto;
    height: 100%;
    padding-right: 5px;
    box-sizing: border-box;
  }
}

.workplace-left_table {
  height: calc(100% - 47px);
}

::v-deep {
  .el-card {
    height: calc(100% - 47px);
    box-sizing: border-box;
    padding-right: 15px;
    .el-card__body {
      height: 100%;
      box-sizing: border-box;
    }
  }
}

.workplace-info {
  padding-bottom: 15px;
  border-bottom: $border-style;
  position: sticky;
  right: 0;
  width: 100%;
  top: 0;
  z-index: 100;
  background: #fff;
  &_name {
    margin: 3px 0 4px;
  }
}

.workplace-info-empty {
  width: 336px;
  padding: 275px 0 0 0;
  margin: 0 auto;
  height: 80%;
  font-size: 16px;
}
</style>